import { SvgIcon } from './svg-icon.model';

export const rmaIcons = [
  {
    name: 'rma-award',
    location: 'mat-icons/rma-award.svg	',
  },
  {
    name: 'rma-bath',
    location: 'mat-icons/rma-bath.svg',
  },
  {
    name: 'rma-bed',
    location: 'mat-icons/rma-bed.svg',
  },
  {
    name: 'rma-floorplan',
    location: 'mat-icons/rma-floorplan.svg',
  },
  {
    name: 'rma-for-sale',
    location: 'mat-icons/rma-forsale.svg',
  },
  {
    name: 'rma-get-reviews',
    location: 'mat-icons/rma-get-reviews.svg',
  },
  {
    name: 'rma-house',
    location: 'mat-icons/rma-house.svg',
  },
  {
    name: 'rma-medal',
    location: 'mat-icons/rma-medal.svg',
  },
  {
    name: 'rma-promoter',
    location: 'mat-icons/rma-promoter.svg',
  },
  {
    name: 'rma-sale',
    location: 'mat-icons/rma-sale.svg',
  },
  {
    name: 'rma-smm',
    location: 'mat-icons/rma-smm.svg',
  },
  {
    name: 'rma-thumbs-down',
    location: 'mat-icons/rma-thumbs-down.svg',
  },
  {
    name: 'rma-thumbs-up',
    location: 'mat-icons/rma-thumbs-up.svg',
  },
  {
    name: 'rma-unverified',
    location: 'mat-icons/rma-unverified.svg',
  },
  {
    name: 'rma-verified',
    location: 'mat-icons/rma-verified.svg',
  },
  {
    name: 'rma-widgets',
    location: 'mat-icons/rma-widgets.svg',
  },
  {
    name: 'rma-reviews',
    location: 'mat-icons/rma-reviews.svg',
  },
  {
    name: 'rma-unicorn',
    location: 'mat-icons/rma-unicorn.svg',
  },
  {
    name: 'rma-stars',
    location: 'mat-icons/rma-stars-plain.svg',
  },
  {
    name: 'social-apple',
    location: 'mat-icons/social-apple.svg',
  },
  {
    name: 'social-facebook',
    location: 'mat-icons/social-facebook.svg',
  },
  {
    name: 'social-facebook-colour',
    location: 'mat-icons/social-facebook-colour.svg',
  },
  {
    name: 'social-google',
    location: 'mat-icons/social-google.svg',
  },
  {
    name: 'social-google-colour',
    location: 'mat-icons/social-google-b.svg',
  },
  {
    name: 'social-instagram',
    location: 'mat-icons/social-instagram.svg',
  },
  {
    name: 'social-instagram-colour',
    location: 'mat-icons/social-instagram-colour.svg',
  },
  {
    name: 'social-linkedin',
    location: 'mat-icons/social-linkedin.svg',
  },
  {
    name: 'social-linkedin-colour',
    location: 'mat-icons/social-linkedIn-colour.svg',
  },
  {
    name: 'social-outlook',
    location: 'mat-icons/social-outlook.svg',
  },
  {
    name: 'social-twitter',
    location: 'mat-icons/social-x.svg',
  },
  {
    name: 'social-twitter-colour',
    location: 'mat-icons/social-x.svg',
  },
  {
    name: 'social-x',
    location: 'mat-icons/social-x.svg',
  },
  {
    name: 'social-x-colour',
    location: 'mat-icons/social-x.svg',
  },
  {
    name: 'social-googlemybusiness-colour',
    location: 'mat-icons/social-googleMyBusiness-colour.svg',
  },
  {
    name: 'google-profile-icon',
    location: 'mat-icons/google-profile-icon.svg',
  },
  {
    name: 'rma-upwards',
    location: 'mat-icons/rma-upwards.svg',
  },
  {
    name: 'platform-realtor',
    location: 'mat-icons/platform-realtor.svg',
  },
  {
    name: 'platform-realtor-base',
    location: 'mat-icons/platform-realtor-base.svg',
  },
  {
    name: 'platform-domain',
    location: 'mat-icons/platform-domain.svg',
  },
  {
    name: 'platform-zillow',
    location: 'mat-icons/platform-zillow.svg',
  },
  {
    name: 'platform-zillow-base',
    location: 'mat-icons/platform-zillow-base.svg',
  },
  {
    name: 'platform-allHomes-base',
    location: 'mat-icons/platform-allHomes-base.svg',
  },
  {
    name: 'platform-allHomes',
    location: 'mat-icons/platform-allHomes.svg',
  },
  {
    name: 'platform-onTheHouse-base',
    location: 'mat-icons/platform-onTheHouse-base.svg',
  },
  {
    name: 'platform-onTheHouse',
    location: 'mat-icons/platform-onTheHouse.svg',
  },
  {
    name: 'platform-reiwa-base',
    location: 'mat-icons/platform-reiwa-base.svg',
  },
  {
    name: 'platform-reiwa',
    location: 'mat-icons/platform-reiwa.svg',
  },
  {
    name: 'platform-soho-base',
    location: 'mat-icons/platform-soho-base.svg',
  },
  {
    name: 'platform-soho',
    location: 'mat-icons/platform-soho.svg',
  },
  {
    name: 'platform-yelp-base',
    location: 'mat-icons/platform-yelp-base.svg',
  },
  {
    name: 'platform-yelp',
    location: 'mat-icons/platform-yelp.svg',
  },
  {
    name: 'rma-no-search-results',
    location: 'mat-icons/rma-no-search-results-border.svg',
  },
  {
    name: 'rma-auto-awesome',
    location: 'mat-icons/rma-auto-awesome.svg',
  },
  {
    name: 'rma-auto-awesome-no-fill',
    location: 'mat-icons/rma-auto-awesome-no-fill.svg',
  },
  {
    name: 'rma-brand-flag',
    location: 'mat-icons/rma-brand-flag.svg',
  },
  {
    name: 'rma-brand-flag-base',
    location: 'mat-icons/rma-brand-flag-base.svg',
  },
  {
    name: 'rma-brand-circle',
    location: 'mat-icons/rma-brand-circle.svg',
  },
  {
    name: 'rma-brand-circle-base',
    location: 'mat-icons/rma-brand-circle-base.svg',
  },
  {
    name: 'rma-open-car-space',
    location: 'mat-icons/rma-open-car-space.svg',
  },
] as const satisfies readonly SvgIcon[];

const rmaIconNames = rmaIcons.map((a) => a.name);

export type CustomIcons = (typeof rmaIconNames)[number];
